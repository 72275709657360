<template>
  <div class="space-center-container">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
      :isIndex="true"
      :beforeList="getCommunityOpsList"
      rowKey="orgId"
      :operateColumnWidth="150"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="addOnClick" />
        <v-button text="导出" :loading="exportLoading" @click="exportOnClick" />
      </template>
      <template #searchSlot>
        <v-select
          label="社区"
          v-model="searchParam.tenantIds"
          placeholder="社区"
          :clearable="false"
          :options="tenantIdsOptions"
          @change="tenantIdsOnChange"
        />
        <v-select label="小区" v-model="searchParam.spaceId" clearable :options="spaceIdOptions" placeholder="小区" />
        <v-input label="幢" v-model="searchParam.buildingNum" clearable type="number" placeholder="幢" />
        <v-input label="单元" v-model="searchParam.unitNum" clearable type="number" placeholder="单元" />
        <v-input label="室" v-model="searchParam.roomNum" clearable type="number" placeholder="室" />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="editOnClick(scope.row)" />
        <v-button v-if="['', null, void 0, NaN].includes(scope.row.houseCode)" text="删除" type="text" @click="deleteOnClick(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import {
  getCommunityOpsUrl,
  getXqLzDyOpsUrl,
  getSpaceCenterListUrl,
  exportSpaceCenterExcelUrl,
  deleteSpaceCenterInfoUrl,
  getSpaceCenterDetailUrl
} from './api.js';

export default {
  name: 'pubCapSpacCenSpaceCenter',
  data() {
    return {
      tenantIdsOptions: [],
      spaceIdOptions: [],
      searchParam: {
        tenantIds: '',
        spaceId: '',
        buildingNum: '',
        unitNum: '',
        roomNum: ''
      },
      beforeChangeFlag: true,
      exportLoading: false,
      tableUrl: getSpaceCenterListUrl,
      headers: [
        {
          prop: 'communityName',
          label: '小区',
          align: 'center'
        },
        {
          prop: 'buildingName',
          label: '楼幢',
          align: 'center'
        },
        {
          prop: 'unitName',
          label: '单元',
          align: 'center'
        },
        {
          prop: 'roomName',
          label: '房号',
          align: 'center'
        },
        {
          prop: 'path',
          label: '地址',
          align: 'center'
        }
      ]
    };
  },
  computed: {},
  created() {
    // if (_localStorage.getItem("userInfo")) {
    //   let userInfo = JSON.parse(_localStorage.getItem("userInfo"));
    //   this.tenantId = userInfo.communityRelated;
    // }
  },
  mounted() {},
  methods: {
    async getCommunityOpsList() {
      const params = { tenantId: this.$store.state.app.userInfo.tenantId };
      const res = await this.$axios.post(getCommunityOpsUrl, this.$qs.stringify(params));
      if (res && res.code === 200) {
        res.data = Array.isArray(res.data) ? res.data : [];
        this.tenantIdsOptions = res.data.map(item => ({ label: item.tenantName, value: item.tenantId }));
        if (this.tenantIdsOptions.length > 0) {
          this.searchParam.tenantIds = this.tenantIdsOptions[0].value;
          this.getXqLzDyOpsList();
        }
      }
    },
    getXqLzDyOpsList() {
      this.$axios
        .get(getXqLzDyOpsUrl, { params: { spaceId: this.searchParam.tenantIds, type: '10' } })
        .then(res => {
          if (res && res.code === 200) {
            res.data = Array.isArray(res.data) ? res.data : [];
            this.spaceIdOptions = res.data.map(item => ({ label: item.name, value: item.spaceId }));
          }
        })
        .catch(() => {});
    },
    tenantIdsOnChange(value) {
      if (this.beforeChangeFlag === false) {
        this.searchParam.spaceId = '';
        this.getXqLzDyOpsList();
        this.$refs.list.search();
      }
      this.beforeChangeFlag = false;
    },
    addOnClick() {
      this.$router.push({
        name: 'pubCapSpacCenSpaceCenterForm',
        query: { hand: 'add', tenantId: this.searchParam.tenantIds }
      });
    },
    exportOnClick() {
      this.exportLoading = true;
      this.$axios
        .post(exportSpaceCenterExcelUrl, this.searchParam, {
          responseType: 'blob',
          onUploadProgress: event => {
            console.log('上传进度 => ', event);
          },
          onDownloadProgress: event => {
            console.log('下载进度 => ', event);
          }
        })
        .then(res => {
          this.exportLoading = false;
          let blob = new Blob([res.data], { type: 'application/vnd.ms-excel;charset=utf-8' });
          let fileName = decodeURI(res.headers['content-disposition']);
          if (fileName) {
            fileName = fileName.substring(fileName.indexOf('=') + 1);
          }
          const link = document.createElement('a');
          link.download = fileName;
          link.style.display = 'none';
          link.href = URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    editOnClick(row) {
      this.$router.push({
        name: 'pubCapSpacCenSpaceCenterForm',
        query: {
          spaceId: row.spaceId,
          tenantId: this.searchParam.tenantIds,
          hand: 'edit'
        }
      });
    },
    deleteOnClick(row) {
      this.$confirm('确定删除吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios
            .post(deleteSpaceCenterInfoUrl, { spaceId: row.spaceId })
            .then(res => {
              if (res && res.code === 200) {
                this.$message.success(res.msg);
                this.$refs.list.search();
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="less" scoped>
.space-center-container {
  box-sizing: border-box;
  height: 100%;
}
</style>
